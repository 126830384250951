import request from '@/utils/request'


// 查询报警记录阅读记录列表
export function listDeviceAlarmMessage(query) {
  return request({
    url: '/gather/gather-device-alarm-message/list',
    method: 'get',
    params: query
  })
}

// 查询报警记录阅读记录分页
export function pageDeviceAlarmMessage(query) {
  return request({
    url: '/gather/gather-device-alarm-message/page',
    method: 'get',
    params: query
  })
}

// 查询报警记录阅读记录详细
export function getDeviceAlarmMessage(data) {
  return request({
    url: '/gather/gather-device-alarm-message/detail',
    method: 'get',
    params: data
  })
}

// 新增报警记录阅读记录
export function addDeviceAlarmMessage(data) {
  return request({
    url: '/gather/gather-device-alarm-message/add',
    method: 'post',
    data: data
  })
}

// 修改报警记录阅读记录
export function updateDeviceAlarmMessage(data) {
  return request({
    url: '/gather/gather-device-alarm-message/edit',
    method: 'post',
    data: data
  })
}

// 删除报警记录阅读记录
export function delDeviceAlarmMessage(data) {
  return request({
    url: '/gather/gather-device-alarm-message/delete',
    method: 'post',
    data: data
  })
}
